/**
 * Created by vladislav on 2/7/2024
 */

connector.config = {
};

connector.sdk = new SdkManager();

connector.player = PlayerInterface();
connector.info = InfoInterface();
connector.platform = PlatformInterface();
connector.social = SocialInterface();
connector.payments = PaymentsInterface();
connector.ads = AdsInterface();
connector.storage = StorageInterface();

connector._playerManager = new PlayerManager();
connector._platformStorage = new PlatformStorage();

connector.initialize = function (options, callback) {
    options = options ? Object.assign({}, options) : {};
    callback = callback || function () {};

    var projectId, source;

    var next = connector.utils.wait(2, function () {
        if (options.config) {
            this.config = options.config;

            if (!this.config.payApiURL) {
                throw "Please specify payApiURL in config";
            }
        } else {
            this.config = {};
        }

        if (source) {
            this.config.source = source;
        }

        if (document.body) {
            connector._initialize(options, callback);
        } else {
            document.addEventListener("DOMContentLoaded", function () {
                connector._initialize(options, callback);
            }, false);
        }
    }.bind(this));

    var script = connector.utils.getCurrentScript();
    var url = script && script.src || document.URL;
    if (url) {
        var index = url.indexOf("?");
        if (index !== -1) {
            var params = connector.utils.getRequestParameters(url.slice(index));
            projectId = params.projectId;
            source = params.source;
        }
    }

    if (options.config) {
        next();
    } else {
        connector._loadConfig(connector.SERVER_URL + "configs/" + projectId + "_config.json", function (data) {
            options.config = data;
            next();
        });
    }

    if (options.products) {
        next();
    } else {
        connector._loadConfig(connector.SERVER_URL + "configs/" + projectId + "_products.json", function (data) {
            options.products = data;
            next();
        });
    }
};

connector.calcBaseUrl = function () {
    return this.config.payApiURL.substring(0, this.config.payApiURL.lastIndexOf("/") + 1);
};

connector._loadConfig = function (url, callback) {
    ConnectorRestClient.load(url, function (config, error) {
        if (!config || error) {
            console.error("failed to load", url, error);
            callback();
            return;
        }

        try {
            config = JSON.parse(config);
        } catch (error) {
            console.error("failed to parse", url, error);
            callback();
            return;
        }

        callback(config);
    });
};

connector._initialize = function (options, callback) {
    this.dataLoader = new SimpleDataLoader();

    Info.initialize();

    var PlatformPluginClass = connector.platform._detectPlugin();
    var platformPlugin = new PlatformPluginClass();
    connector.platform._installPlugin(platformPlugin);

    platformPlugin.initialize(function () {
        SimpleDataLoader.INIT_TYPES(options.migrateLocalStorage);

        platformPlugin.loadUserId();

        this._countryManager = new CountryManager();
        this._paymentsManager = new PaymentsManager(options.products);
        this._adsManager = new AdsManager({
            manualPermissions: options.manualPermissions
        });
        this._socialManager = new SocialManager();

        this._platformStorage.start();

        var SocialPluginClass = connector.social._detectPlugin();
        if (SocialPluginClass) {
            connector.social._installPlugin(new SocialPluginClass());
        }

        var PaymentsPluginClass = connector.payments._detectPlugin();
        if (PaymentsPluginClass) {
            connector.payments._installPlugin(new PaymentsPluginClass());
        }

        var AdsPluginClass = connector.ads._detectPlugin();
        if (AdsPluginClass) {
            connector.ads._installPlugin(new AdsPluginClass());
        }

        callback();

        if (window.onConnectorInit) {
            setTimeout(window.onConnectorInit, 0);
        }

        connector.platform.connect();
        connector.social.connect();
        connector.payments.connect();
        connector.ads.connect();
    }.bind(this));
};