/**
 * Created by razial on 22.02.2024
 */

connector.CODE_SUCCEED = 0;
connector.CODE_FAILED = 1;
connector.CODE_CANCELLED = 2;
connector.CODE_PENDING = 3;

connector.STATUS_CONNECTED = "connected";
connector.STATUS_DISCONNECTED = "disconnected";
connector.STATUS_DISABLED = "disabled";

connector.utils = {
    INTERVALS: {
        second: 1,
        minute: 60,
        hour: 3600,
        day: 86400,
        week: 7 * 86400,
        month: 30 * 86400
    },

    parseInterval: function (intervalStr) {
        var INTERVALS = connector.utils.INTERVALS;
        var value = parseInt(intervalStr) * 1000;

        for (var name in INTERVALS) {
            if (intervalStr.indexOf(name) !== -1) {
                var period = INTERVALS[name];
                return value * period;
            }
        }

        return value;
    },

    once: function (func) {
        return function () {
            if (func) {
                var oldFunc = func;
                func = undefined;
                return oldFunc.apply(this, arguments);
            }
        };
    },

    waitNoMore: function (timeout, callback) {
        var waitTimeout = setTimeout(function () {
            waitTimeout = undefined;
            callback();
        }, timeout);

        return function () {
            if (waitTimeout !== undefined) {
                clearTimeout(waitTimeout);
                waitTimeout = undefined;
                callback.apply(this, arguments);
            }
        };
    },

    accumulate: function (interval, callback) {
        var timeout = undefined;
        var self;
        var args;

        return function () {
            self = this;
            args = arguments;

            if (timeout === undefined) {
                timeout = setTimeout(function () {
                    clearTimeout(timeout);
                    timeout = undefined;

                    var s = self, a = args;
                    self = undefined;
                    args = undefined;

                    callback.apply(s, a);
                }, interval);
            }
        };
    },

    postpone: function (delay, callback) {
        var self;
        var args;
        var timeout;

        return function () {
            self = this;
            args = arguments;

            clearTimeout(timeout);
            timeout = setTimeout(function () {
                callback.apply(self, args);
            }, delay);
        };
    },

    wait: function (n, callback) {
        if (n === 0) {
            callback();
            return;
        }

        var total = 0;
        return function () {
            total++;
            if (total === n) {
                callback();
            }
        };
    },

    timeredThrottle: function (delay, callback) {
        var self, args, timeout;

        var exec = function () {
            if (timeout || !args) {
                return;
            }

            timeout = setTimeout(function () {
                timeout = undefined;
                exec();
            }, delay);

            var s = self, a = args;
            self = undefined;
            args = undefined;

            callback.apply(s, a);
        };

        return function () {
            self = this;
            args = arguments;

            exec();
        };
    },

    loadSdk: function (path, options) {
        options.onSuccessOnce = options.onSuccessOnce || connector.utils.once(options.onSuccess);
        options.onFailureOnce = options.onFailureOnce || connector.utils.once(options.onFailure);

        var onSuccess = options.onSuccessOnce;
        var onFailure = options.onFailureOnce;

        if (options.attempt === undefined) {
            options.attempt = 3;
        }

        var script = document.createElement("script");
        if (options.crossorigin !== false) {
            script.setAttribute("crossorigin", "anonymous");
        }
        script.setAttribute("type", "text/javascript");
        script.setAttribute("src", path);
        if (options.attrs) {
            Object.keys(options.attrs).forEach(function (attrName) {
                script.setAttribute(attrName, options.attrs[attrName]);
            });
        }

        var loadTimeout;

        var onFailureCallback = function () {
            clearTimeout(loadTimeout);
            script.onload = undefined;
            script.onerror = undefined;

            if (options.attempt > 1) {
                if (document.body.contains(script)) {
                    document.body.removeChild(script);
                }
                options.attempt--;
                connector.utils.loadSdk(path, options);
            } else {
                onFailure();
            }
        };

        script.onload = function () {
            clearTimeout(loadTimeout);
            onSuccess();
        };
        script.onerror = onFailureCallback;
        loadTimeout = setTimeout(onFailureCallback, 30000);

        document.body.appendChild(script);
    },

    getCurrentScript: function () {
        if (document.currentScript) {
            return document.currentScript;
        }

        var scripts = document.getElementsByTagName("script");

        for (var i = 0; i < scripts.length; i++) {
            if (scripts[i].src && scripts[i].src.indexOf(connector.SDK_NAME) !== -1) {
                return scripts[i];
            }
        }
    },

    getRequestParameters: function (source, keepAsIs) {
        var res = {};
        var url = source || window.location.search;
        if (url) {
            url = url.substr(1); // Drop the leading '?' / '#'
            var nameValues = url.split("&");

            for (var i = 0; i < nameValues.length; i++) {
                var nameValue = nameValues[i].split("=");
                var name = nameValue[0];
                var value = nameValue[1] || "";
                if (!keepAsIs) {
                    try {
                        value = decodeURIComponent(value.replace(/\+/g, " "));
                    } catch (e) {
                        console.error("getRequestParameters decodeURIComponent - " + value);
                    }
                }
                res[name] = value;
            }
        }
        return res;
    },

    stringify: function (obj) {
        try {
            if (typeof obj === "object" && !(obj instanceof Error)) {
                return JSON.stringify(obj);
            }
        } catch (e) {
            // do nothing
        }
        return String(obj);
    },

    isIFrame: function () {
        return window.top !== window.self;
    },

    clone: function (object, recursive) {
        if (object === null) {
            return null;
        }

        if (typeof object !== "object") {
            return object;
        }

        var r;

        if (Array.isArray(object)) {
            r = [];
            for (var i = 0; i < object.length; i++) {
                if (recursive) {
                    r.push(connector.utils.clone(object[i], true));
                } else {
                    r.push(object[i]);
                }
            }
            return r;
        }

        r = {};
        for (var name in object) {
            if (recursive) {
                r[name] = connector.utils.clone(object[name], true);
            } else {
                r[name] = object[name];
            }
        }
        return r;
    },

    setUrlHash: function (data) {
        var params = connector.utils.getRequestParameters(window.location.hash);

        for (var key in data) {
            var value = data[key];

            if (value === undefined) {
                delete params[key];
            } else {
                params[key] = value;
            }
        }

        window.location.hash = Object.keys(params).map(function (objectKey) {
            return objectKey + "=" + params[objectKey];
        }).join("&");
    },

    formatAmount: function (amount) {
        if (amount < 1000) {
            return amount;
        }

        var thousands = Math.floor(amount / 1000);

        amount -= thousands * 1000;

        if (amount < 10) {
            amount = "00" + amount;
        } else if (amount < 100) {
            amount = "0" + amount;
        }

        var DELIMETER = " ";
        return thousands + DELIMETER + amount;
    },

    substract: function (array1, array2, getKey) {
        getKey = getKey || function (item) {
            return item;
        };

        array1 = array1 || [];
        array2 = array2 || [];

        var array2Object = {};
        array2.forEach(function (item) {
            array2Object[getKey(item)] = true;
        });

        return array1.filter(function (item) {
            return array2Object[getKey(item)] === undefined;
        });
    },

    loadBase64: function (url, callback) {
        if (url.startsWith("data:image")) {
            callback(url);
            return;
        }

        var img = new Image();
        img.crossOrigin = "anonymous";

        img.src = url;
        img.onload = function () {
            var canvas = document.createElement("canvas");
            canvas.height = img.naturalHeight;
            canvas.width = img.naturalWidth;
            canvas.getContext("2d").drawImage(img, 0, 0);

            callback(canvas.toDataURL("image/jpg"));
        };
        img.onerror = function (err) {
            console.log("[connector] Error loading image:" + url, err);

            callback();
        };
    }
};
