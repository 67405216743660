/**
 * Created by vladislav on 9/6/2022
 */

var GDCom = function (name) {
    Platform.call(this, name || connector.GDCOM, {
        sdk: SdkManager.SDK_GDCOM
    });

    connector._gdComOldId = connector.utils.getRequestParameters().gdcom_userid;
};

GDCom.prototype = Object.create(Platform.prototype);
GDCom.prototype.constructor = GDCom;

GDCom.prototype.loadUserId = function () {
    this._userID = connector.dataLoader.load(SimpleDataLoader.TYPES.USER_ID);
    if (!this._userID) {
        if (connector._gdComOldId) {
            this.setUserID(connector._gdComOldId);
        } else {
            this.setUserID(this.generateTmpId());
        }
    }
};

GDCom.prototype.onSdkEvent = function (event) {
    switch (event.name) {
        case "SDK_GAME_START":
            if (!this._pageHidden) {
                this.resume();
            }
            break;
        case "SDK_GAME_PAUSE":
            this.pause();
            break;
        case "SDK_GDPR_TRACKING":
            // this event is triggered when your user doesn't want to be tracked
            break;
        case "SDK_GDPR_TARGETING":
            // this event is triggered when your user doesn't want personalised targeting of ads and such
            break;

        case "DISPLAYAD_IMPRESSION":
            break;
    }
};

GDCom.prototype.getLocalStoragePrefix = function () {
    return "_plinga";
};