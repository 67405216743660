/**
 * Created by slava on 4/5/17.
 */

var TestSocial = function () {
    Social.call(this, connector.TEST);
};

TestSocial.prototype = Object.create(Social.prototype);
TestSocial.prototype.constructor = TestSocial;

TestSocial.prototype.isSupportsNativeShare = function () {
    return true;
};

TestSocial.prototype.isSupportsNativeInvite = function () {
    return true;
};

TestSocial.prototype.isSupportsNativeRequest = function () {
    return true;
};

TestSocial.prototype.updateSession = function (id) {
    var expire;
    if (typeof DateShift !== "undefined") {
        expire = new DateShift.Date();
    } else {
        expire = new Date();
    }
    expire.setMonth(expire.getMonth() + 3);

    this.id = id;
    this.token = "123";
    this.expires = expire.getTime();

    document.cookie = "tsid=" + this.id + "; path=/; expires=" + expire.toUTCString();
};

TestSocial.prototype.removeSession = function () {
    this.id = undefined;
    this.token = undefined;
    this.expires = undefined;

    document.cookie = "tsid=; path=/; expires=Thu, 01 Jan 1970 00:00:01 GMT;";
};

TestSocial.prototype.loadSession = function () {
    var match = document.cookie && document.cookie.match(/tsid=([^;]+)/);
    if (match) {
        this.updateSession(match[1]);
    }
};

TestSocial.prototype._connect = function (callback) {
    this.id = undefined;
    this.expires = undefined;
    this.token = undefined;

    setTimeout(function () {
        this.loadSession();
        callback(connector.STATUS_CONNECTED);
    }.bind(this), 1000);
};

TestSocial.prototype._loadTestImage = function (url, callback) {
    var image = new Image();
    document.body.appendChild(image);
    image.style.zIndex = "10";
    image.style.margin = "auto auto";

    var close = function () {
        document.body.removeChild(image);
    };

    image.onload = function () {
        setTimeout(function () {
            callback(close);
        }, 100);
    };
    image.src = url;
};

TestSocial.prototype.share = function (data, callback) {
    this._loadTestImage(data.image, function (close) {
        if (confirm("Do you to post " + JSON.stringify(data) + "?")) {
            close();

            callback(connector.CODE_SUCCEED);
        } else {
            close();

            callback(connector.CODE_FAILED);
        }
    });
};

TestSocial.prototype.isExpired = function () {
    var now;
    if (typeof DateShift !== "undefined") {
        now = new DateShift.Date();
    } else {
        now = Date.now();
    }

    return !this.expires || this.expires < now;
};

TestSocial.prototype.isLoggedIn = function () {
    return Boolean(this.id);
};

TestSocial.prototype._getUserID = function () {
    return this.id;
};

TestSocial.prototype.getAccessToken = function () {
    return this.isExpired() ? undefined : this.token;
};

TestSocial.prototype._login = function (callback) {
    this._createLoginOverlay(function (id) {
        if (id) {
            this.updateSession(id);
        } else {
            this.removeSession();
        }

        callback();
    }.bind(this));
};

TestSocial.prototype.logout = function () {
    this.removeSession();
};

TestSocial.prototype.invite = function (data, callback) {
    this._loadTestImage(data.image, function (close) {
        if (confirm("Invite " + JSON.stringify(data))) {
            close();

            callback(connector.CODE_SUCCEED);
        } else {
            close();

            callback(connector.CODE_FAILED);
        }
    });
};

TestSocial.prototype.request = function (data, callback) {
    delete data.element;

    callback = callback || function () {};

    this._loadTestImage(data.image, function (close) {
        if (confirm("Do you to post " + JSON.stringify(data) + "?")) {
            close();

            callback(connector.CODE_SUCCEED);
        } else {
            close();

            callback(connector.CODE_FAILED);
        }
    });
};

TestSocial.prototype._listFriends = function (callback) {
    setTimeout(function () {
        callback(connector.CODE_SUCCEED, Object.values(TestSocial.USERS));
    }, 500);
};

TestSocial.prototype._listInvitableFriends = function (callback) {
    setTimeout(function () {
        callback(connector.CODE_SUCCEED, Object.values(TestSocial.INVITABLE_USERS));
    }, 500);
};

TestSocial.prototype._aboutMe = function (callback) {
    setTimeout(function () {
        callback(connector.CODE_SUCCEED, {
            name: "FakeFB User",
            avatar: "https://graph.facebook.com/100001828353521/picture?height=200&width=200",
            id: connector.platform.getUserID()
        });
    }, 500);
};

TestSocial.prototype._createLoginOverlay = function (callback) {
    var styles = document.createElement("style");
    styles.innerHTML = ""
        + ".test-social-overlay {"
        + "    position: fixed;"
        + "    left: 0;"
        + "    top: 0;"
        + "    right: 0;"
        + "    bottom: 0;"
        + "    background-color: rgba(0, 0, 0, 0.5);"
        + "    z-index: 1000;"
        + "    display: flex;"
        + "    justify-content: center;"
        + "    align-items: center;"
        + "}"
        + ".test-social-form {"
        + "    position: relative;"
        + "    background-color: #fff;"
        + "    padding: 20px;"
        + "    border-radius: 8px;"
        + "    text-align: center;"
        + "    font-size: 18px;"
        + "    display: flex;"
        + "    flex-direction: column;"
        + "    justify-content: center;"
        + "    align-items: center;"
        + "    gap: 20px;"
        + "}"
        + ".test-social-current-user {"
        + "    font-weight: bold;"
        + "}"
        + ".test-social-choose-user > div:first-child {"
        + "    margin-bottom: 10px;"
        + "}"
        + ".test-social-choose-user > div:last-child {"
        + "    display: flex;"
        + "    justify-content: space-around;"
        + "}"
        + ".test-social-choose-user > div:last-child > div {"
        + "    background-color: #007BFF;"
        + "    color: #fff;"
        + "    padding: 10px;"
        + "    border-radius: 4px;"
        + "    cursor: pointer;"
        + "    flex: 1;"
        + "    margin: 0 5px;"
        + "    text-align: center;"
        + "}"
        + ".test-social-choose-user > div:last-child > div:hover {"
        + "    background-color: #0056b3"
        + "}"
        + ".test-social-custom-user > div:first-child {"
        + "    margin-bottom: 10px;"
        + "}"
        + ".test-social-custom-user input {"
        + "    width: 300px;"
        + "    padding: 8px;"
        + "    border: 1px solid #ccc;"
        + "    border-radius: 4px;"
        + "}"
        + "#test-social-login {"
        + "    width: 240px;"
        + "    background-color: #28A745;"
        + "    color: #fff;"
        + "    padding: 12px;"
        + "    border-radius: 4px;"
        + "    cursor: pointer;"
        + "}"
        + "#test-social-login:hover {"
        + "    background-color: #218838;"
        + "}"
        + "#test-social-close {"
        + "    position: absolute;"
        + "    top: 10px;"
        + "    right: 10px;"
        + "    background-color: transparent;"
        + "    border: none;"
        + "    font-size: 20px;"
        + "    cursor: pointer;"
        + "    color: #aaa;"
        + "}"
        + "#test-social-close:hover {"
        + "    color: #000;"
        + "}";
    document.head.appendChild(styles);

    var overlay = document.createElement("div");
    overlay.className = "test-social-overlay";
    document.body.appendChild(overlay);

    var onLogin = function (id) {
        document.body.removeChild(overlay);
        document.head.removeChild(styles);

        callback(id);
    };

    overlay.innerHTML = ""
        + "<div class='test-social-form'>"
        + "    <div>Fake login</div>"
        + "    <div class='test-social-current-user'>Current user id:<br>" + connector.platform.getUserID() + "</div>"
        + "    <div class='test-social-choose-user'>"
        + "        <div>Choose user:</div>"
        + "        <div id='test-social-choose-user-list'></div>"
        + "    </div>"
        + "    <div class='test-social-custom-user'>"
        + "        <div>Or enter custom id:</div>"
        + "        <input type='text' id='test-social-custom-user-input'>"
        + "    </div>"
        + "    <div id='test-social-login'>Login</div>"
        + "    <button id='test-social-close'>&times;</button>"
        + "</div>";

    var users = document.getElementById("test-social-choose-user-list");

    Object.values(TestSocial.USERS).forEach(function (user) {
        var element = document.createElement("div");
        element.innerText = user.name;
        element.onclick = function () {
            onLogin(user.id);
        };
        users.append(element);
    });

    var login = document.getElementById("test-social-login");
    login.onclick = function () {
        var input = document.getElementById("test-social-custom-user-input");
        onLogin(input.value);
    };

    var close = document.getElementById("test-social-close");
    close.onclick = function () {
        onLogin(undefined);
    };
};

TestSocial.isAppropriate = function () {
    return connector.platform.oneOf(connector.TEST);
};

TestSocial.USERS = {
    alpha: {
        name: "Alpha",
        avatar: "https://graph.facebook.com/41803173/picture?height=200&width=200"
    },
    beta: {
        name: "Beta",
        avatar: "https://graph.facebook.com/692982514/picture?height=200&width=200"
    },
    gamma: {
        name: "Gamma",
        avatar: "https://graph.facebook.com/100028444590540/picture?height=200&width=200"
    }
};

Object.keys(TestSocial.USERS).forEach(function (id) {
    TestSocial.USERS[id].id = id;
});

TestSocial.INVITABLE_USERS = {
    invitable_1: {
        name: "Invitable1",
        avatar: "https://graph.facebook.com/100001008129789/picture?height=200&width=200"
    },
    invitable_2: {
        name: "Invitable2",
        avatar: "https://graph.facebook.com/100011706990070/picture?height=200&width=200"
    },
    invitable_3: {
        name: "Invitable3",
        avatar: "https://graph.facebook.com/100001575006940/picture?height=200&width=200"
    },
    invitable_4: {
        name: "Invitable4",
        avatar: "https://graph.facebook.com/100000318466656/picture?height=200&width=200"
    }
};

Object.keys(TestSocial.INVITABLE_USERS).forEach(function (id) {
    TestSocial.INVITABLE_USERS[id].id = id;
});
