/**
 * Created by mac on 4/8/18
 */

var OKAds = function () {
    AdsPlugin.call(this, connector.OK, {
        sdk: SdkManager.SDK_OK,
        cantLoadAndPlayInParallel: true,
        cantLoadAfterPlay: true,
        customImpressionEvent: true
    });
};

OKAds.prototype = Object.create(AdsPlugin.prototype);
OKAds.prototype.constructor = OKAds;

OKAds.isAppropriate = function () {
    return connector.platform.oneOf(connector.OK);
};

OKAds.prototype.isStickySupported = function () {
    return true;
};

OKAds.prototype._checkAdBlock = function (callback) {
    connector.platform.getPlugin().listeners.isAdBlockEnabled = connector.utils.waitNoMore(200, function (result, data) {
        if (result === undefined && data === undefined) {
            this._checkAdBlock(callback);
            return;
        }

        callback(result === "ok" && data === "true");
    }.bind(this));

    FAPI.invokeUIMethod("isAdBlockEnabled");
};

OKAds.prototype.checkAdBlock = function (callback) {
    this._checkAdBlock(callback);
};

OKAds.prototype._connect = function (callback) {
    if (typeof FAPI === "undefined" || !FAPI.UI.showAd || !FAPI.UI.showLoadedAd) {
        callback(connector.STATUS_DISCONNECTED);
        return;
    }

    if (connector.info.isInApp) {
        var isNativeSupported = FAPI.UI.isNativeAdSupported && FAPI.UI.isNativeAdSupported();
        if (!isNativeSupported) {
            callback(connector.STATUS_DISCONNECTED);
            return;
        }
    }

    callback(connector.STATUS_CONNECTED);
};

OKAds.prototype._cacheAd = function (name, callback) {
    if (AdsPlugin.REWARDED === name) {
        this._cacheCallback = callback;
        this.cachedRewardedTime = Date.now();

        FAPI.UI.loadAd();
    } else if (AdsPlugin.INTERSTITIAL === name) {
        callback(connector.CODE_SUCCEED, true);
    }
};

OKAds.prototype._playAd = function (name, ad, callback) {
    if (name === AdsPlugin.REWARDED) {
        this._playRewardedCallback = callback;
        this.cachedRewardedTime = undefined;

        FAPI.UI.showLoadedAd();
    } else if (name === AdsPlugin.INTERSTITIAL) {
        this._playInterstitialCallback = callback;
        FAPI.UI.showAd();
        callback(); // FAPI.UI.showAd is loading ad first, if ad is not loaded it do nothing, and we don't want to wait in this case
    }
};

OKAds.prototype.isAdExpired = function (name) {
    return name === AdsPlugin.REWARDED && this.cachedRewardedTime < Date.now() - OKAds.REWARDED_EXPIRED_TIME;
};

OKAds.prototype.isStickyAvailable = function () {
    return connector.info.isMobile ? this.isLandscape() : true;
};

OKAds.prototype.isLandscape = function () {
    return window.innerHeight < window.innerWidth;
};

OKAds.prototype.calcBannersCountMax = function () {
    if (!connector.info.isMobile && this.isLandscape() && this.bannersData.count_options.includes(OKAds.AD_BANNERS_COUNT_MAX)) {
        return OKAds.AD_BANNERS_COUNT_MAX;
    }

    return OKAds.AD_BANNERS_COUNT_DEFAULT;
};

OKAds.prototype.initBannersData = function (f, stop) {
    if (this.bannersData) {
        f();
        return;
    }

    connector.platform.getPlugin().listeners.getBannerFormats = connector.utils.once(function (code, data) {
        if (code === "ok" && data) {
            this.bannersData = JSON.parse(data);
            f();
        } else {
            stop();
        }
    }.bind(this));

    FAPI.invokeUIMethod("getBannerFormats");
};

OKAds.prototype.setBannerFormatThin = function (f, stop) {
    if (!connector.info.isMobile) {
        f();
        return;
    }

    if (!this.bannersData.supported.thin) {
        stop(ERRORS.SHOW_STICKY.NOT_SUPPORTED);
        return;
    }

    connector.platform.getPlugin().listeners.setBannerFormat = connector.utils.once(function (code, data) {
        if (data === "set_banner_format_success" || (code === "ok" && data === "true")) {
            f();
        } else {
            stop();
        }
    });

    FAPI.invokeUIMethod("setBannerFormat", "thin");
};

OKAds.prototype.initBannersCount = function (f) {
    var countMax = this.calcBannersCountMax();
    if (this.bannersData.current_count === countMax) {
        f();
        return;
    }

    connector.platform.getPlugin().listeners.setBannersCount = connector.utils.once(function (c) {
        if (c === "ok") {
            this.bannersData.current_count = countMax;
        }

        if (this.bannersData.current_count) {
            f();
        } else {
            stop();
        }
    }.bind(this));

    FAPI.invokeUIMethod("setBannersCount", countMax);
};

OKAds.prototype.getBannerSize = function () {
    var bannersCount = this.bannersData.current_count;
    var size = { width: OKAds.AD_BANNER_WIDTH, height: OKAds.AD_BANNER_HEIGHT };
    if (connector.info.isMobile) {
        size = {
            width: 0,
            height: OKAds.AD_THIN_BANNER_HEIGHT_MOB
        };
    } else if (bannersCount > 1) {
        size = {
            width: OKAds.AD_BANNER_WIDTH * bannersCount + OKAds.AD_BANNERS_OFFSET * (bannersCount - 1),
            height: OKAds.AD_BANNER_HEIGHT
        };
    }
    return size;
};

OKAds.prototype.showSticky = function (callback) {
    var onSuccess = function () {
        callback(connector.CODE_SUCCEED, this.getBannerSize());
    }.bind(this);

    var player = new ConnectorActionPlayer([
        this.initBannersData.bind(this),
        this.setBannerFormatThin.bind(this),
        this.initBannersCount.bind(this),

        function (f, stop) {
            connector.platform.getPlugin().listeners.requestBannerAds = connector.utils.once(function (code) {
                if (code === "ok") {
                    f();
                } else {
                    stop();
                }
            });
            FAPI.invokeUIMethod("requestBannerAds");
        },

        function (f, stop) {
            connector.platform.getPlugin().listeners.isBannerAdsVisible = connector.utils.once(function (code, data) {
                if (code === "ok") {
                    if (data === "true") {
                        onSuccess();
                    } else {
                        f();
                    }
                } else {
                    stop();
                }
            });

            FAPI.invokeUIMethod("isBannerAdsVisible");
        },

        function (f, stop) {
            var bannerSize = this.getBannerSize();

            connector.platform.getPlugin().listeners.showBannerAds = connector.utils.once(function (code, data) {
                if (code !== "ok") {
                    stop();
                    return;
                }

                if (data !== "true") {
                    stop(ERRORS.SHOW_STICKY.NO_STICKY);
                    return;
                }

                var requestBannerAds = function (code, data) {
                    connector.platform.getPlugin().listeners.requestBannerAds = requestBannerAds;

                    if (code === "ok" && data === "ad_shown") {
                        if (!connector.ads.isStickyPlaying) {
                            connector._adsManager.onStickyRestored(bannerSize);
                        }

                        this.bus.trigger("sticky:render");
                    }

                    if (data === "hidden_by_user") {
                        connector._adsManager.onStickyClosedByUser();
                    }
                }.bind(this);

                connector.platform.getPlugin().listeners.requestBannerAds = requestBannerAds;
                f();
            }.bind(this));

            FAPI.invokeUIMethod("showBannerAds", "bottom");
        }.bind(this)
    ]);

    player.onFailure(function (code) {
        callback(code || ERRORS.SHOW_STICKY.UNKNOWN);
    });

    player.play(onSuccess);
};

OKAds.prototype.closeSticky = function (callback) {
    connector.platform.getPlugin().listeners.hideBannerAds = connector.utils.once(function (code) {
        if (code !== "ok") {
            callback(ERRORS.CLOSE_STICKY.UNKNOWN);
            return;
        }

        connector.platform.getPlugin().listeners.isBannerAdsVisible = connector.utils.once(function (code, data) {
            if (code !== "ok") {
                callback(ERRORS.CLOSE_STICKY.UNKNOWN);
                return;
            }

            if (data !== "false") {
                callback(ERRORS.CLOSE_STICKY.UNKNOWN);
                return;
            }

            connector.platform.getPlugin().listeners.requestBannerAds = undefined;
            connector.platform.getPlugin().listeners.showBannerAds = undefined;
            connector.platform.getPlugin().listeners.hideBannerAds = undefined;
            connector.platform.getPlugin().listeners.isBannerAdsVisible = undefined;

            callback(connector.CODE_SUCCEED);
        });

        FAPI.invokeUIMethod("isBannerAdsVisible");
    });

    FAPI.invokeUIMethod("hideBannerAds");
};

OKAds.prototype.onSdkEvent = function (method, result, data) {
    switch (method) {
        case "loadAd":
            this.windowApiLoadAdCallbackRewarded(AdsPlugin.REWARDED, result, data);
            break;
        case "showLoadedAd":
            this.windowApiPlayAdCallbackRewarded(AdsPlugin.REWARDED, result, data);
            break;
        case "showAd":
            this.windowApiCallbackInterstitial(AdsPlugin.INTERSTITIAL, result, data);
            break;
    }
};

OKAds.prototype.windowApiLoadAdCallbackRewarded = function (name, result, data) {
    var callback = this._cacheCallback;
    if (!callback) {
        return;
    }

    if (result === "error") {
        this._cacheCallback = undefined;
        callback(AdsPlugin.ERROR_LOADING_FAILED);
    } else if (["ad_prepared", "ready"].indexOf(data) !== -1) {
        this._cacheCallback = undefined;
        callback(connector.CODE_SUCCEED, true);
    }
};

OKAds.prototype.windowApiPlayAdCallbackRewarded = function (name, result, data) {
    var callback = this._playRewardedCallback;
    if (!callback) {
        return;
    }

    if (["ad_prepared", "ready"].indexOf(data) !== -1) {
        // do nothing
    } else if (result === "error") {
        this._playRewardedCallback = undefined;

        if (data === "skip") {
            callback(ERRORS.SHOW_REWARDED.CLOSED_BY_USER);
        } else if (data === "ad_expired") {
            callback(ERRORS.SHOW_REWARDED.AD_EXPIRED);
        } else {
            callback(ERRORS.SHOW_REWARDED.UNKNOWN);
        }
    } else if (["ad_shown", "complete"].indexOf(data) !== -1) {
        this._playRewardedCallback = undefined;
        this.onImpressionEvent(name);
        callback(connector.CODE_SUCCEED);
    }
};

OKAds.prototype.windowApiCallbackInterstitial = function (name, result, data) {
    var callback = this._playInterstitialCallback;
    if (!callback) {
        return;
    }

    if (["ad_prepared", "ready"].indexOf(data) !== -1) {
        // do nothing
    } else if (result === "error") {
        this._playInterstitialCallback = undefined;

        callback(data === "skip" ? ERRORS.SHOW_INTERSTITIAL.CLOSED_BY_USER : ERRORS.SHOW_INTERSTITIAL.UNKNOWN);
    } else if (["no_ads", "skip"].indexOf(data) !== -1) {
        this._playInterstitialCallback = undefined;
    } else if (["ad_shown", "complete"].indexOf(data) !== -1) {
        this._playInterstitialCallback = undefined;
        this.onImpressionEvent(name);
    }
};

OKAds.AD_BANNER_HEIGHT = 104;
OKAds.AD_BANNER_WIDTH = 404;
OKAds.AD_BANNER_HEIGHT_MOB = 106;
OKAds.AD_THIN_BANNER_HEIGHT_MOB = 56;

OKAds.AD_BANNERS_OFFSET = 16;

OKAds.AD_BANNERS_COUNT_DEFAULT = 1;
OKAds.AD_BANNERS_COUNT_MAX = 2;

OKAds.REWARDED_EXPIRED_TIME = connector.utils.parseInterval("14 minutes");