/**
 * Created by vladislav on 2/9/2024
 */

/**
 * Working with payments is implemented via **connector.payments** interface.
 *
 * ## Supported Platforms
 * <ul class="platforms-list">
 *   <li>
 *       ✅ <img src="images/yandex.png"> Yandex Games
 *   </li>
 *   <li>
 *       ✅ <img src="images/vk.png"> VK Games
 *   </li>
 *   <li>
 *       ✅ <img src="images/ok.png"> OK Games
 *   </li>
 *   <li>
 *       ✅ <img src="images/kongregate.png"> Kongregate
 *   </li>
 *     <li>
 *       ✅ <img src="images/crazy.png"> Crazy
 *   </li>
 *   <li>
 *       ✅ <img src="images/instant.png"> Facebook Instant Games
 *   </li>
 *   <li>
 *       ✅ <img src="images/android.png"> Android
 *   </li>
 *   <li>
 *       ✅ <img src="images/apple.png"> iOS
 *   </li>
 *   <li>
 *       ✅ <img src="images/amazon.png"> Amazon
 *   </li>
 *   <li>
 *       ✅ <img src="images/microsoft.png"> Microsoft Store
 *   </li>
 *   <li>
 *       ✅ <img src="images/msn.png"> MSN
 *   </li>
 *   <li>
 *       ✅ <img src="images/facebook.png"> Facebook
 *   </li>
 *   <li>
 *       ✅ <img src="images/fotostrana.png"> Fotostrana
 *   </li>
 *   <li>
 *       ✅ <img src="images/mbga.png"> Mobage
 *   </li>
 *   <li>
 *       ✅ <img src="images/sp_mbga.png"> SP Mobage
 *   </li>
 *   <li>
 *       ✅ <img src="images/mm.png"> My World
 *   </li>
 *   <li>
 *       ✅ <img src="images/rustore.png"> Rustore
 *   </li>
 *   <li>
 *       ✅ <img src="images/samsung.png"> Samsung
 *   </li>
 *   <li>
 *       ✅ <img src="images/draugiem.png"> Draugiem
 *   </li>
 *   <li>
 *       ✅ <img src="images/plinga.png"> Plinga
 *   </li>
 *   <li>
 *       ✅ <img src="images/mygames.png"> Mygames
 *   </li>
 *   <li>
 *       ✅ <img src="images/wortal.png"> Wortal
 *   </li>
 * </ul>
 *
 *
 *  You can check whether payments are supported on platform with
 *  ``` javascript
 *  if (connector.payments.isAvailable) {
 *    // Payments are supported
 *  }
 *  ```
 * ## Purchase product
 *
 * To purchase product, you need to pass an ID or Tag of product.
 *
 * ``` javascript
 * const result = await connector.payments.purchase({ id: "gold" });
 * if (result) {
 *   // Purchase is successful
 *   console.log(result.product, result.purchase);
 * }
 *```
 *
 * Purchase product events
 *
 * ```
 * // Purchase is successful
 * connector.payments.on("purchase", ({ purchase, product } => {});
 *
 * // Purchase failed
 * // Error types
 * //  "purchase_already_open"
 * //  "product_not_found"
 * //  "login_required"
 * //  "email_required"
 * //  "invalid_email"
 * //  "purchase_timeout"
 * //  "purchase_canceled_by_user"
 * //  "purchase_unknown_error"
 * connector.payments.on("error:purchase", (error => {});
 * ```
 *
 * ## Consume product
 *
 * To use a purchase, you need to pass in a purchase object
 *
 * ``` javascript
 * const result = await connector.payments.consume(purchase);
 * if (result) {
 *   // consume is successful
 *   console.log(result.product, result.purchase);
 * }
 * ```
 *
 * Consume product events
 *
 * ```
 * // Consume is successful
 * connector.payments.on("consume", ({ purchase, product } => {});
 *
 * // Consume failed
 * // Error types
 * //  "consume_unknown_error"
 * connector.payments.on("error:consume", (error => {});
 * ```
 *
 * ## Getting products
 *
 * ```
 * // Get a list of all products
 * const products = connector.payments.products;
 *
 * // Get a specific product by ID or Tag
 * const product = connector.payments.getProduct("gold");
 * ```
 *
 * ## Restore not consumed purchases
 *
 * You can subscribe to on restore event to receive not consumed consumable purchases.
 * ```
 * connector.payments.on("restore", (purchase) => {
 *   const success = await connector.payments.consume(purchase);
 *   if (success) {
 *     // Consume success
 *   }
 * });
 * ```
 *
 * @namespace Payments
 * */

/**
 * @event Payments#purchase
 * @property {Payments.PurchaseResult} result - Purchase result
 */
/**
 * @event Payments#error:purchase
 * @property {string} error - Error
 */
/**
 * @event Payments#consume
 * @property {Payments.PurchaseResult} error - Error
 */
/**
 * @event Payments#error:consume
 * @property {string} error - Error
 */
/**
 * @event Payments#restore
 * @property {Payments.Purchase} purchase - Purchase
 */

/**
 * @typedef Purchase
 * @memberof Payments
 * @property {string} paymentId Purchase ID
 * @property {string} userId User ID
 * @property {string} source Platform source
 * @property {string} method Payment method
 * @property {number} price Price in local currency
 * @property {string} currency Currency code
 * @property {string} type Purchase type
 * @property {object} payload Platform-specific data e.g. payment token
 */

/**
 * @typedef Product
 * @memberof Payments
 * @property {string} itemId Item ID
 * @property {string} productId Product ID
 * @property {number} price Price in local currency
 * @property {string} currency Currency code
 * @property {number} priceUSD Price in USD
 * @property {string} textPrice Text price
 */

/**
 * @typedef {Object} PurchaseResult
 * @memberof Payments
 * @property {Payments.Purchase} purchase - Purchase
 * @property {Payments.Product} product - Product
 */

var PaymentsInterface = function () {
    return Object.assign({
        /**
         * @type {Array<Payments.Product>}
         * @memberof Payments
         * @desc Returns a list of available products
         * */
        get products() {
            return connector.payments.getPlugin().listProducts();
        },
        /**
         * @type {Boolean}
         * @memberof Payments
         * @desc Are payments supported on this platform
         * */
        get isAvailable() {
            return connector.payments.getStatus() !== connector.STATUS_DISABLED;
        },
        /**
         * @type {Boolean}
         * @memberof Payments
         * @desc Whether a user needs to be logged in to make a payment
         * */
        get isLoginRequired() {
            return connector._paymentsManager.isLoginRequired();
        },
        /**
         * @type {Boolean}
         * @memberof Payments
         * @desc Whether an email needs to be provided to make a payment
         * */
        get isEmailRequired() {
            return connector.payments.oneOf(connector.YOOKASSA);
        },
        /**
         * @type {Boolean}
         * @memberof Payments
         * @desc Are subscriptions supported on this platform
         * */
        get isSubscriptionsAvailable() {
            return connector._paymentsManager.isSubscriptionsAvailable();
        }
    }, CommonInterface(), {
        _detectPlugin: PaymentsDetector,

        /**
         * @function purchase
         * @memberof Payments
         * @desc Purchase product by provided ID or Tag
         * @param {object} options - Options
         * @param {string} options.id - Product ID
         * @param {string} options.tag - Product Tag
         * @param {string} options.email - Email
         * @param {object} options.context - Context
         * @return {Promise<Payments.PurchaseResult>}
         * */
        purchase: function (options) {
            return new Promise(function (resolve) {
                connector.payments.purchaseCallback(options, resolve);
            });
        },

        purchaseCallback: function (options, callback) {
            connector._paymentsManager.purchase(options, callback);
        },

        /**
         * @function consume
         * @memberof Payments
         * @desc Consume purchase
         * @param {Payments.Purchase} purchase - Purchase
         * @return {Promise<Payments.PurchaseResult>}
         * */
        consume: function (purchase) {
            return new Promise(function (resolve) {
                connector.payments.consumeCallback(purchase, resolve);
            });
        },

        consumeCallback: function (purchase, callback) {
            connector._paymentsManager.consume(purchase, callback);
        },

        stopCurrentPurchase: function () {
            connector._paymentsManager.stopPurchasePlayer();
        },

        /**
         * @function getProduct
         * @memberof Payments
         * @desc Returns product by provided ID or Tag
         * @param {string} productIdOrTag - Product ID or Tag
         * @return {Payments.Product}
         * */
        getProduct: function (productIdOrTag) {
            return this.getPlugin().getProduct(productIdOrTag);
        },

        getPurchaseState: function () {
            return this.getPlugin().getPurchaseState();
        },

        loadProductsThrottled: function () {
            if (connector.payments.isConnected()) {
                connector._paymentsManager.tryLoadProductsThrottled();
            }
        },

        getTextPrice: function (price) {
            var plugin = this.getPlugin();
            return plugin.getTextPrice(price);
        },

        convertPrice: function (price) {
            var plugin = this.getPlugin();
            return plugin.convertPrice(price);
        },

        getExchangeRate: function () {
            var plugin = this.getPlugin();
            return plugin.getExchangeRate();
        },

        restore: function () {
            connector._paymentsManager.restore();
        },

        restoreSubscriptions: function (callback) {
            var plugin = this.getPlugin();
            if (plugin.restoreSubscriptions) {
                plugin.restoreSubscriptions(callback);
            } else {
                callback();
            }
        },

        isVirtualCurrency: function () {
            var plugin = this.getPlugin();
            return plugin.isVirtualCurrency();
        },

        getSubscriptionStatus: function (options, callback) {
            this.getPlugin().getSubscriptionStatus(options, callback);
        },

        notifyActive: function () {
            this.getPlugin().notifyActive();
        }
    });
};