/**
 * https://msstart-doc-dev.azurewebsites.net/docs/sdk-apis/in-app-purchases/
 * https://www.msn.com/en-us/play/games/testing/cg-test-game?dev-playground=https://mergecraft-staging.labsystech.ru/publish/html5-staging/msstart&item=flights:prg-cg-iap-pub
 *
 * Created by andrey on 18.11.2024
 */

var MSStartPayments = function () {
    Payments.call(this, connector.MSSTART, {
        sdk: SdkManager.SDK_MSSTART
    });
};

MSStartPayments.prototype = Object.create(Payments.prototype);
MSStartPayments.prototype.constructor = MSStartPayments;

MSStartPayments.prototype.getProductId = function (config) {
    return config.id.msstart;
};

MSStartPayments.prototype._loadProducts = function (callback) {
    var productIds = this.listProductIdsOfType(["consumable", "nonConsumable"]);
    var loadedProducts = {};

    var player = new ConnectorActionPlayer([
        function (f) {
            if (!connector.social.isConnected()) {
                var handler = connector.social.on("changeStatus", connector.utils.waitNoMore(10000, function () {
                    handler.clear();
                    f();
                }));
            } else {
                f();
            }
        },

        function (f, stop) {
            if (!connector.social.isLoggedIn()) {
                console.log("load products failed - not logged in");
                stop();
            } else {
                f();
            }
        },

        function (f) {
            Promise.all(productIds.map(function (productId) {
                return new Promise(function (resolve) {
                    $msstart.iap.getAddOnAsync({
                        productId: productId
                    }).then(function (response) {
                        if (response.code || !response.price || productId !== response.productId) {
                            console.log("Failed load " + productId, response);
                            return;
                        }

                        loadedProducts[productId] = response;

                        var price = response.price;

                        var ourProduct = this.getProduct(productId);
                        if (ourProduct) {
                            ourProduct.currency = price.currencyCode;
                            ourProduct.price = price.listPrice;
                            this.currency = price.currencyCode || this.currency;
                            ourProduct.textPrice = this.getTextPrice(ourProduct.price);
                        }
                    }.bind(this)).catch(function (error) {
                        console.log("Failed load " + productId, error);
                    }).finally(resolve);
                }.bind(this));
            }.bind(this))).finally(f);
        }.bind(this)
    ]);

    player.onFailure(function () {
        callback(connector.CODE_FAILED);
    });

    player.onSuccess(function () {
        console.log("Success load products", loadedProducts);
        callback(connector.CODE_SUCCEED, Object.keys(loadedProducts));
    });

    player.play();
};

MSStartPayments.prototype.purchase = function (product, callback) {
    var restoreOwned = function () {
        this.restoreProduct(product.productId, function (code, purchase) {
            if (code !== connector.CODE_SUCCEED) {
                code = ERRORS.PURCHASE.ALREADY_OPEN;
            }
            callback(code, purchase);
        });
    }.bind(this);

    $msstart.iap.purchaseAsync({
        productId: product.productId
    }).then(function (response) {
        console.log("success purchase", response);

        var receipt = response.receipt;

        var purchase = this.createPurchase(receipt.orderId, product, {
            receiptSignature: response.receiptSignature,
            receipt: receipt
        });

        callback(connector.CODE_SUCCEED, purchase);
    }.bind(this)).catch(function (e) {
        console.log("error purchase", e);

        var code = ERRORS.PURCHASE.UNKNOWN;
        var errorCode = e && e.code;
        var errorDescription = (e && e.description || "").toLowerCase();
        var extraErrorCode = e && e.extra && e.extra.errorCode || "";

        if (extraErrorCode === "PUR-UserAlreadyOwnsContent"
            || errorCode === "IAP_PURCHASE_RECEIPT_FAILURE" && errorDescription.startsWith("purchase succeeded but receipt")) {
            restoreOwned();
            return;
        }

        if (errorDescription === "user dismissed the purchase dialog.") {
            code = ERRORS.PURCHASE.CANCEL;
        }

        callback(code);
    });
};

MSStartPayments.prototype.consume = function (purchase, callback) {
    console.log("consume start", purchase);

    $msstart.iap.consumeAsync({
        productId: purchase.productId
    }).then(function (response) {
        console.log("consume success", response);

        callback(connector.CODE_SUCCEED);
    }).catch(function (e) {
        console.log("consume error", e);

        callback(ERRORS.CONSUME.UNKNOWN);
    });
};

MSStartPayments.prototype.validate = function (purchase, callback) {
    console.log("Start validate", purchase);

    ConnectorRestClient.post("/msstartpayments/validate", purchase.payload || {}, function (response) {
        if (response === "ok") {
            callback(connector.CODE_SUCCEED, purchase);
            console.log("Purchase validation success");
        } else {
            console.log("Purchase validation error", response);
            callback(connector.CODE_FAILED);
        }
    }, function () {
        console.log("Purchase validation network error - skip server validation");
        callback(connector.CODE_SUCCEED, purchase);
    });
};

MSStartPayments.prototype.restore = function (callback) {
    if (!connector.social.isLoggedIn()) {
        callback(connector.CODE_FAILED);
        return;
    }

    var productIds = this.listProductIdsOfType(["consumable", "nonConsumable"]);
    var productId = productIds[Math.floor(Math.random() * productIds.length)];
    this.restoreProduct(productId, callback);
};

MSStartPayments.prototype.restoreProduct = function (productId, callback) {
    $msstart.iap.getPurchasesAsync({
        productId: productId
    }).then(function (response) {
        // console.log("Restore success " + productId, response);

        var receipt = response.receipt;
        var product = this.getProduct(productId);
        var purchase = this.createPurchase(receipt.orderId, product, {
            receiptSignature: response.receiptSignature,
            receipt: receipt
        });

        callback(connector.CODE_SUCCEED, purchase);
    }.bind(this)).catch(function () {
        // console.log("Restore failed " + productId, error);
        callback(connector.CODE_FAILED);
    });
};

MSStartPayments.isAppropriate = function () {
    return connector.platform.oneOf(connector.MSSTART) && connector.config.msstart.iap && connector.config.debugMode;
};