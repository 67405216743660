/**
 * Created by vladislav on 9/6/2022
 */

var Platform = function (pluginName, options) {
    BasePlugin.call(this, "platform", pluginName, options);

    this.paused = false;

    this.callbacks = {};
    this.nativeEventListeners = {};
};

Platform.prototype = Object.create(BasePlugin.prototype);
Platform.prototype.constructor = Platform;

Platform.prototype.initialize = function (callback) {
    this._pageVisibilitySubscribe();
    this._initialize(callback);
};

Platform.prototype._pageVisibilitySubscribe = function () {
    if (document.hidden) {
        this._togglePageHide(true);
    }

    if (typeof document.hidden !== "undefined") {
        ["visibilitychange", "webkitvisibilitychange"].forEach(function (event) {
            document.addEventListener(event, function () {
                this._togglePageHide(document.hidden);
            }.bind(this), false);
        }.bind(this));
    }

    window.addEventListener("pagehide", function () {
        this._togglePageHide(true);
    }.bind(this), false);

    window.addEventListener("pageshow", function () {
        this._togglePageHide(false);
    }.bind(this), false);
};

Platform.prototype._togglePageHide = function (hidden) {
    this._pageHidden = hidden;
    if (hidden) {
        this.pause();
    } else {
        this.resume();
    }
};

Platform.prototype._initialize = function (callback) {
    callback();
};

Platform.getGroupUrl = function () {
    switch (connector.info.source) {
        case "ok":
            if (connector.info.isMobile) {
                return "https://m.ok.ru/group/" + connector.config.ok.groupId;
            }
            return "https://ok.ru/group/" + connector.config.ok.groupId;
        case "vk":
            if (connector.info.isMobile) {
                return "https://m.vk.com/" + connector.config.vk.groupId;
            }
            return "https://vk.com/" + connector.config.vk.groupId;
        case "mm":
            if (connector.info.isMobile) {
                return "https://m.my.mail.ru/community/" + connector.config.mm.groupId;
            }
            return "https://my.mail.ru/community/" + connector.config.mm.groupId;
        case "mbga":
            return "http://yahoo-mbga.jp/group/" + connector.config.mbga.groupId;
        case "sp_mbga":
            return "http://sp.mbga.jp/_grp_view?g=" + connector.config.mbga.groupId;
        case "draugiem":
            return "https://www.draugiem.lv/" + connector.config.draugiem.groupId;
        case "fotostrana":
            return "https://fotostrana.ru/public/" + connector.config.fotostrana.groupId;
        case "discord":
            return connector.config.discord.groupUrl;
        default:
            if (connector.config.instant.groupId) {
                return "https://www.facebook.com/" + connector.config.instant.groupId;
            }
            return "https://www.facebook.com/" + connector.config.facebook.groupId;
    }
};

Platform.getGameUrl = function (source) {
    return connector.utils.guessPlatformURL({ source: source });
};

Platform.getRateUrl = function () {
    switch (connector.info.source) {
        case "test":
            return connector.utils.guessPlatformURL({ source: "android" });
        case "android":
            return "market://details?id=" + connector.config.android.package;
        case "amazon":
            return "amzn://apps/android?p=" + connector.config.amazon.package;
        case "macos":
        case "ios":
            return "itms-apps://itunes.apple.com/app/id" + connector.config.ios.id;
    }
};

Platform.getExternalUrl = function (url) {
    if (url[0] === "/") {
        url = url.substr(1);
    }
    if (connector.platform.oneOf(connector.INSTANT, connector.TEST) || url.startsWith("http")) {
        return url;
    }

    return connector.config.deployment + "/" + url;
};

Platform.prototype.openUrl = function (url) {
    setTimeout(window.open.bind(window, url, "_blank"), 0);
};

Platform.prototype.nativeInviteFriends = function (data, callback) {
    this.callNative("MessagesPlugin.inviteFriends", {
        title: data.title,
        message: data.text,
        link: connector.utils.guessPlatformURL()
    }, callback);
};

Platform.prototype.loadUserId = function () {
    this._userID = connector.dataLoader.load(SimpleDataLoader.TYPES.USER_ID);
    if (!this._userID) {
        this.setUserID(this.generateTmpId());
    }
};

Platform.prototype.setUserID = function (id) {
    if (this._userID === id) {
        return;
    }

    console.log("Platform.setUserID", this._userID, id);

    var oldId = this._userID;

    this._userID = id;

    connector.dataLoader.save(SimpleDataLoader.TYPES.USER_ID, this._userID);

    this.bus.trigger("userIdChanged", {
        oldId: oldId,
        newId: id
    });
};

Platform.prototype.getUserID = function () {
    return this._userID;
};

Platform.prototype.generateTmpId = function () {
    return "__" + Math.floor(Math.random() * 1000000000000000);
};

Platform.prototype.canReview = function () {
    return false;
};

Platform.prototype.getLocalStoragePrefix = function () {
    return "";
};

Platform.prototype.listSuggestedLanguages = function () {
    return [connector.LANGUAGE_ENGLISH];
};

Platform.prototype.hasAudioControls = function () {
    return false;
};

Platform.prototype.isAudioEnabled = function () {
    return undefined;
};

Platform.prototype.requestReview = function (callback) {
    if (this.isConnected()) {
        this._requestReview(callback);
    } else {
        callback(connector.CODE_FAILED);
    }
};

Platform.prototype._requestReview = function (callback) {
    callback(connector.CODE_FAILED);
};

Platform.prototype.canCreateShortcut = function () {
    if (!this.isConnected()) {
        return false;
    }

    return this._canCreateShortcut();
};

Platform.prototype._canCreateShortcut = function () {
    return false;
};

Platform.prototype.createShortcut = function (callback) {
    if (callback) {
        callback(connector.CODE_FAILED);
    }
};

Platform.prototype.getAccessToken = function () {

};

Platform.DecodeWebViewData = function (data) {
    if (data) {
        try {
            return decodeURIComponent(data);
        } catch (e) {
            return data;
        }
    }
    return data;
};

Platform.prototype.parsePayload = function (callback) {
    if (connector.info.isNative) {
        callback();
        return;
    }

    callback(connector.utils.getRequestParameters());
};

Platform.prototype.parseOtherWays = function (callback) {
    callback();
};

Platform.prototype.nativeEvent = function (name, data) {
    if (this.oneOf(connector.RUSTORE, connector.AMAZON, connector.ANDROID, connector.GPG, connector.DIGITAL, connector.IOS, connector.MACOS, connector.MICROSOFT)) {
        data = Platform.DecodeWebViewData(data);
        data = data && JSON.parse(data);
    }

    console.log("Platform.nativeEvent " + name + ": " + JSON.stringify(data));

    var listener = name.split(".")[0];
    var event = name.split(".")[1];

    if (this.nativeEventListeners[listener]) {
        this.nativeEventListeners[listener](event, data);
    }
};

Platform.prototype.callNative = function (method, options, callback) {
    if (!callback && typeof options === "function") {
        callback = options;
        options = undefined;
    }

    var previous = this.callbacks[method];
    if (previous) {
        console.log("Platform.callNative - second call to method " + method);
        this.callCallback(method, connector.CODE_FAILED);
    }

    var params = options || {};
    params._hasOptions = Boolean(options);
    params._hasCallback = Boolean(callback);

    // console.log("Platform.callNative " + method + " - " + JSON.stringify(options));
    this.callbacks[method] = callback;
    this._callNative(method, params);
};

Platform.prototype._callNative = function () {

};

Platform.prototype.callCallback = function (method, code, options) {
    if (this.oneOf(connector.RUSTORE, connector.AMAZON, connector.ANDROID, connector.GPG, connector.DIGITAL, connector.IOS, connector.MACOS, connector.MICROSOFT)) {
        options = Platform.DecodeWebViewData(options);
        options = options ? JSON.parse(options) : {};
    }

    console.log("Platform.callCallback " + method + " - " + code + " - " + JSON.stringify(options));

    var callback = this.callbacks[method];
    if (callback) {
        delete this.callbacks[method];
        callback(code, options);
    }
};

Platform.prototype.isTester = function () {
    var testers = connector.config.testers && connector.config.testers[connector.info.source];

    if (!testers) {
        return false;
    }

    var ids = testers.ids;
    var deviceIds = testers.deviceIds;

    var userId = this.getUserID();
    var deviceId = connector.info.deviceID;

    return userId && ids && ids.includes(userId)
        || deviceId && deviceIds && deviceIds.includes(deviceId);
};

Platform.prototype.locationReload = function () {
    window.location.reload();
};

Platform.prototype.isPaused = function () {
    return this.paused;
};

Platform.prototype.pause = function () {
    if (!this.paused) {
        this.paused = true;

        this.bus.trigger("pause");
    }
};

Platform.prototype.resume = function () {
    if (this.paused) {
        this.paused = false;

        this.bus.trigger("resume");
    }
};

Platform.prototype.switchGame = function (url) {
    connector.platform.openUrl(url);
};

Platform.prototype.getSafePadding = function () {
    if (!this.safeAreaDiv) {
        this.safeAreaDiv = document.createElement("div");
        this.safeAreaDiv.style.position = "absolute";
        this.safeAreaDiv.style.top = "env(safe-area-inset-top)";
        this.safeAreaDiv.style.bottom = "env(safe-area-inset-bottom)";
        this.safeAreaDiv.style.left = "env(safe-area-inset-left)";
        this.safeAreaDiv.style.right = "env(safe-area-inset-right)";
        document.body.appendChild(this.safeAreaDiv);
    }

    var getStyle = function (prop) {
        if (typeof window.getComputedStyle !== "undefined") {
            return parseInt(window.getComputedStyle(this.safeAreaDiv).getPropertyValue(prop)) || 0;
        }
        if (typeof this.safeAreaDiv.currentStyle !== "undefined") {
            return parseInt(this.safeAreaDiv.currentStyle[prop]) || 0;
        }
        return 0;
    }.bind(this);

    return {
        top: getStyle("top"),
        bottom: getStyle("bottom"),
        left: getStyle("left"),
        right: getStyle("right")
    };
};

Platform.prototype.getFrameSize = function () {
    return cc.size(window.innerWidth, window.innerHeight);
};