/**
 * Created by vladislav on 9/6/2022
 */

var AndroidPlatform = function () {
    AndroidBase.call(this, connector.ANDROID);
};

AndroidPlatform.prototype = Object.create(AndroidBase.prototype);
AndroidPlatform.prototype.constructor = AndroidPlatform;

AndroidPlatform.prototype.canReview = function () {
    return true;
};

AndroidPlatform.prototype._requestReview = function (callback) {
    this.callNative("ReviewPlugin.requestReview", function (code) {
        callback(code);
    });
};

AndroidPlatform.prototype.parsePayload = function (callback) {
    var data = connector.dataLoader.load(SimpleDataLoader.TYPES.ANDROID_INSTALL_REFERRER) || {};

    if (!data.event) {
        data.event = Date.now();
        connector.dataLoader.save(SimpleDataLoader.TYPES.ANDROID_INSTALL_REFERRER, data);
    }

    if (data.event + connector.utils.parseInterval("1 day") < Date.now()) {
        callback();
        return;
    }

    this.callNative("AdvertisingPlugin.getInstallReferrer", function (code, response) {
        var referrerUrl = response && response.referrerUrl;
        if (!referrerUrl || !referrerUrl.includes("&")) {
            callback();
            return;
        }

        var params = new URLSearchParams(referrerUrl);
        var campaign = params.get("utm_campaign");

        if (campaign) {
            console.log("channel extracted " + campaign);
        }

        if (connector.config.debugMode) {
            this.bus.trigger("stream:console", "REFERRER_URL", referrerUrl);
        }

        callback({ channel: campaign });
    }.bind(this));
};