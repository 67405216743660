/**
 * Created by slava on 4/12/17.
 */

var ConnectorRestClient = {
    METHODS: {
        GET: "GET",
        POST: "POST"
    },

    isLocalhost: function () {
        if (!location.host) {
            return false;
        }

        return !connector.info.isNative && (location.host === "localhost" || location.host === "127.0.0.1" || location.host.indexOf("192.168.") === 0);
    },

    getDefaultTimeout: function () {
        return connector.platform.oneOf(connector.WECHAT) ? 20000 : 10000;
    },

    getXMLHttpRequest: function () {
        return typeof XMLHttpRequest !== "undefined" ? new XMLHttpRequest() : new ActiveXObject("MSXML2.XMLHTTP");
    },

    get: function (path, data, onSuccess, onError, options) {
        this.send(this.METHODS.GET, path, data, onSuccess, onError, options);
    },

    post: function (path, data, onSuccess, onError, options) {
        this.send(this.METHODS.POST, path, data, onSuccess, onError, options);
    },

    load: function (path, callback) {
        callback = callback || function () {};
        
        this.send(this.METHODS.GET, path, undefined, function (result) {
            callback(result);
        }, function (response) {
            callback(undefined, {
                status: response.status,
                responseText: response.errorMessage
            });
        }, {
            fullPath: true,
            raw: true
        });
    },

    send: function (httpMethod, path, data, onSuccess, onError, options) {
        options = options || {};
        data = data || {};

        if (connector.platform.oneOf(connector.DIGITAL, connector.DIGITALWEB, connector.PLAYABLE)) {
            return;
        }

        var timeout = options.timeout || this.getDefaultTimeout();
        var fullPath = path.indexOf("http") !== 0 && path.indexOf("/.proxy/") !== 0 && !options.fullPath ? connector.config.payApiURL + path : path;

        if (options.queryParams) {
            var paramsArray = [];
            for (var paramName in options.queryParams) {
                if (Object.prototype.hasOwnProperty.call(options.queryParams, paramName)) {
                    paramsArray.push(paramName + "=" + options.queryParams[paramName]);
                }
            }

            if (paramsArray.length > 0) {
                fullPath += "?" + paramsArray.join("&");
            }
        }

        var reqListener;

        var oReq = this.getXMLHttpRequest();

        var fallbackTimeout = setTimeout(function () {
            reqListener.call({
                status: -1,
                responseText: "our timeout"
            });
        }, timeout + 1000);

        reqListener = function () {
            if (fallbackTimeout !== undefined) {
                clearTimeout(fallbackTimeout);
                fallbackTimeout = undefined;
            }

            if (this.status >= 200 && this.status < 300) {
                if (onSuccess) {
                    var result;
                    if (options.raw) {
                        result = this.responseText;
                    } else {
                        try {
                            result = this.responseText && JSON.parse(this.responseText);
                        } catch (e) {
                            throw new Error("parse json error: path - " + path + ", message - " + e.message);
                        }
                    }

                    onSuccess(result);
                }
            } else {
                if (this.responseText) {
                    this.errorMessage = (this.responseText.split("\n")[0] || "").trim();
                }

                console.log("XMLHttpRequest FAILED", httpMethod, this.status, this.errorMessage, fullPath);

                if (onError) {
                    onError(this);
                }
            }

            onSuccess = function () {};
            onError = function () {};
        }.bind(oReq);

        oReq.ontimeout = reqListener;
        oReq.onload = reqListener;
        oReq.onerror = reqListener;
        oReq.onabort = reqListener;

        oReq.open(httpMethod, fullPath);
        oReq.timeout = timeout;

        oReq.setRequestHeader("Content-Type", "application/json");

        if (options.authorization) {
            oReq.setRequestHeader("authorization", options.authorization);
        } else {
            oReq.setRequestHeader("Cache-Control", "no-cache");
        }

        oReq.send(JSON.stringify(data));
    }
};
