/**
 * Created by vladislav on 4/2/2024
 */

var PaymentsDetector = function () {
    var PluginClass;

    var types = [
        typeof NoPayments !== "undefined" && NoPayments,
        typeof DiscordPayments !== "undefined" && DiscordPayments,
        typeof YooKassaPayments !== "undefined" && YooKassaPayments,
        typeof WebViewPayments !== "undefined" && WebViewPayments,
        typeof RuStorePayments !== "undefined" && RuStorePayments,
        typeof MSStartPayments !== "undefined" && MSStartPayments,
        typeof XsollaPayments !== "undefined" && XsollaPayments,
        typeof TestPayments !== "undefined" && TestPayments,
        typeof InstantPayments !== "undefined" && InstantPayments,
        typeof SPMobagePayments !== "undefined" && SPMobagePayments,
        typeof OKPayments !== "undefined" && OKPayments,
        typeof MobileMyMailRuPayments !== "undefined" && MobileMyMailRuPayments,
        typeof SamsungPayments !== "undefined" && SamsungPayments,
        typeof MyMailRuPayments !== "undefined" && MyMailRuPayments,
        typeof VKPayments !== "undefined" && VKPayments,
        typeof MobagePayments !== "undefined" && MobagePayments,
        typeof FotoStranaPayments !== "undefined" && FotoStranaPayments,
        typeof DraugiemPayments !== "undefined" && DraugiemPayments,
        typeof YandexPayments !== "undefined" && YandexPayments,
        typeof MicrosoftPayments !== "undefined" && MicrosoftPayments,
        typeof PlingaPayments !== "undefined" && PlingaPayments,
        typeof KongregatePayments !== "undefined" && KongregatePayments,
        typeof WortalPayments !== "undefined" && WortalPayments,
        typeof FacebookPayments !== "undefined" && FacebookPayments,
        typeof MygamesPayments !== "undefined" && MygamesPayments,
        typeof WechatPayments !== "undefined" && WechatPayments
    ];

    for (var i = 0; i < types.length; i++) {
        if (types[i] && types[i].isAppropriate()) {
            PluginClass = types[i];
            break;
        }
    }

    return PluginClass;
};